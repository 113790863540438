import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import FeatureCard from '../components/feature-card'
import Question from '../components/question'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>shellHero</title>
        <meta
          name="description"
          content="shellHero is a platform designed to help (aspiring) sysadmins, SREs and DevOps engineers improve their troubleshooting abilities. Whether you are a beginner or an experienced professional, shellHero offers the resources you need to excel in your role or nail that next interview."
        />
        <meta property="og:title" content="shellHero" />
        <meta
          property="og:description"
          content="shellHero is a platform designed to help (aspiring) sysadmins, SREs and DevOps engineers improve their troubleshooting abilities. Whether you are a beginner or an experienced professional, shellHero offers the resources you need to excel in your role or nail that next interview."
        />
      </Helmet>
      <div className="home-header">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <span className="home-logo">SHELLHERO</span>
          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
            <nav className="home-links">
              <a href="#about">About</a>
              <a href="#features" className="home-nav2">
                Features
              </a>
              <a href="#price" className="home-nav3">
                Pricing
              </a>
              <span className="home-nav4">Team</span>
              <span className="home-nav5">Blog</span>
            </nav>
            <div className="home-buttons">
              <Link to="/login" className="home-login button">
                Login
              </Link>
              <Link to="/register" className="home-register button">
                Register
              </Link>
            </div>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div className="home-nav">
              <div className="home-top">
                <span className="home-logo1">SHELLHERO</span>
                <div data-thq="thq-close-menu" className="home-close-menu">
                  <svg viewBox="0 0 1024 1024" className="home-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="home-links1">
                <span className="home-nav11">About</span>
                <span className="home-nav21">Features</span>
                <span className="home-nav31">Pricing</span>
                <span className="home-nav41">Team</span>
                <span className="home-nav51">Blog</span>
              </nav>
              <div className="home-buttons1">
                <button className="home-login1 button">Login</button>
                <button className="home-register1 button">Register</button>
              </div>
            </div>
            <div>
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="home-hero">
        <div className="home-hero1">
          <div className="home-container01">
            <h1 className="home-hero-heading heading1">
              Level up your troubleshooting skills
            </h1>
            <span className="home-hero-sub-heading">Introducing shellHero</span>
            <div className="home-btn-group">
              <button className="home-hero-button1 button">Get Started</button>
              <button className="home-hero-button2 button">Learn More →</button>
            </div>
          </div>
        </div>
      </div>
      <div id="about" className="home-details">
        <div className="home-details1">
          <div className="home-container02">
            <span className="home-text sectionTitle">
              <span>Details</span>
              <br></br>
            </span>
            <h2 className="home-details-heading heading2">
              Master the art of troubleshooting
            </h2>
            <span className="home-details-sub-heading">
              shellHero is a platform designed to help (aspiring) sysadmins,
              SREs and DevOps engineers improve their troubleshooting abilities.
              With a wide range of realistic scenarios, you can practice and
              refine your skills in a safe environment. Whether you are a
              beginner or an experienced professional, shellHero offers the
              resources you need to excel in your role or nail that next
              interview.
            </span>
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1631624215749-b10b3dd7bca7?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDQyfHxwcm9ncmFtbWluZ3xlbnwwfHx8fDE3MDA0Nzc1OTl8MA&amp;ixlib=rb-4.0.3&amp;w=400"
            className="home-details-image"
          />
        </div>
      </div>
      <div className="home-features">
        <div className="home-features-container">
          <div id="features" className="home-features1">
            <div className="home-container03">
              <span className="home-text03 sectionTitle">
                <span>features</span>
                <br></br>
              </span>
              <h2 className="home-features-heading heading2">
                Enhance Troubleshooting Skills
              </h2>
              <span className="home-features-sub-heading">
                Experience a platform designed to improve your troubleshooting
                capabilities and excel in sysadmin interviews.
              </span>
            </div>
            <div className="home-container04">
              <FeatureCard
                Heading="Troubleshooting Scenarios"
                SubHeading="Access a wide range of real-world troubleshooting scenarios to enhance your problem-solving skills."
              ></FeatureCard>
              <FeatureCard
                Heading="Training Mode"
                SubHeading="Practice troubleshooting in a simulated environment with step-by-step guidance and hints."
              ></FeatureCard>
              <FeatureCard
                Heading="Interview Preparation"
                SubHeading="Prepare for sysadmin interviews by solving challenging troubleshooting scenarios commonly asked in interviews."
              ></FeatureCard>
              <FeatureCard
                Heading="Company Integration"
                SubHeading="Customize and integrate shellHero into your company's interview process to assess candidates' troubleshooting abilities."
              ></FeatureCard>
            </div>
          </div>
        </div>
      </div>
      <div className="home-pricing">
        <div id="price" className="home-pricing1">
          <div className="home-container05">
            <span className="home-text06 sectionTitle">
              <span>Pricing</span>
              <br></br>
            </span>
            <h2 className="home-pricing-heading heading2">
              Choose the Right Plan for You
            </h2>
            <span className="home-pricing-sub-heading">
              Unlock the power of troubleshooting with ShellHero
            </span>
          </div>
          <div className="home-container06">
            <div className="home-pricing-card">
              <div className="home-container07">
                <span className="home-text09 heading3">Free</span>
                <span className="home-free-plan-description">
                  Access basic troubleshooting scenarios for free
                </span>
              </div>
              <div className="home-container08">
                <span className="home-text10">
                  <span>$</span>
                  <span></span>
                </span>
                <span className="home-free-plan-price">0</span>
              </div>
              <div className="home-container09">
                <div className="home-container10">
                  <span className="home-text13">✔</span>
                  <span className="home-free-plan-features">
                    <span>Limited challenge options</span>
                    <br></br>
                  </span>
                </div>
                <div className="home-container11">
                  <span className="home-text16">✔</span>
                  <span className="home-free-plan-features1">
                    Access to community support
                  </span>
                </div>
                <div className="home-container12">
                  <span className="home-text17">✔</span>
                  <span className="home-free-plan-features2">
                    Basic progress tracking
                  </span>
                </div>
                <div className="home-container13">
                  <span className="home-text18">✔</span>
                  <span className="home-free-plan-features3">
                    Free Plan Feature
                  </span>
                </div>
              </div>
              <button className="home-button button">Continue with Free</button>
            </div>
            <div className="home-pricing-card1">
              <div className="home-container14">
                <span className="home-text19 heading3">Pro</span>
                <span className="home-basic-plan-description">
                  Unlock more advanced troubleshooting scenarios
                </span>
              </div>
              <div className="home-container15">
                <span className="home-text20">
                  <span>$</span>
                  <span></span>
                </span>
                <span className="home-basic-plan-pricing">10</span>
                <span className="home-text23">/ month</span>
              </div>
              <div className="home-container16">
                <div className="home-container17">
                  <span className="home-text24">✔</span>
                  <span className="home-text25">All features of FREE plan</span>
                </div>
                <div className="home-container18">
                  <span className="home-text26">✔</span>
                  <span className="home-basic-plan-features">
                    Access to all troubleshooting scenarios
                  </span>
                </div>
                <div className="home-container19">
                  <span className="home-text27">✔</span>
                  <span className="home-basic-plan-features1">
                    Priority email support
                  </span>
                </div>
                <div className="home-container20">
                  <span className="home-text28">✔</span>
                  <span className="home-basic-plan-features2">
                    Advanced progress tracking
                  </span>
                </div>
                <div className="home-container21">
                  <span className="home-text29">✔</span>
                  <span className="home-basic-plan-features3">
                    Ability to save progress
                  </span>
                </div>
              </div>
              <button className="home-button1 button">
                <span>
                  <span className="home-text31">Coming soon</span>
                  <br></br>
                </span>
              </button>
            </div>
            <div className="home-pricing-card2">
              <div className="home-container22">
                <span className="home-text33 heading3">
                  <span>Enterprise</span>
                  <br></br>
                </span>
                <span className="home-pro-plan-description">
                  Train your employees and offer challenges to candidates
                </span>
              </div>
              <div className="home-container23">
                <span className="home-pro-plan-pricing">Get in Touch</span>
              </div>
              <div className="home-container24">
                <div className="home-container25">
                  <span className="home-text36">✔</span>
                  <span className="home-text37">
                     All features of BASIC plan
                  </span>
                </div>
                <div className="home-container26">
                  <span className="home-text38">✔</span>
                  <span className="home-pro-plan-features">
                    Unlimited challenges per month
                  </span>
                </div>
                <div className="home-container27">
                  <span className="home-text39">✔</span>
                  <span className="home-pro-plan-features1">
                    Priority email and chat support
                  </span>
                </div>
                <div className="home-container28">
                  <span className="home-text40">✔</span>
                  <span className="home-pro-plan-features2">
                    Detailed progress tracking and analytics
                  </span>
                </div>
              </div>
              <button className="home-button2 button">Coming Soon</button>
            </div>
          </div>
        </div>
      </div>
      <div className="home-gallery"></div>
      <div className="home-banner">
        <div className="home-banner1">
          <h1 className="home-banner-heading heading2">
            Become a troubleshooting expert
          </h1>
          <span className="home-banner-sub-heading">
            Enhance your sysadmin skills with real-world scenarios
          </span>
          <button className="home-banner-button button">Learn more</button>
        </div>
      </div>
      <div className="home-faq">
        <div className="home-faq-container">
          <div className="home-faq1">
            <div className="home-container29">
              <span className="home-text41 sectionTitle">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="home-text44 heading2">Common questions</h2>
              <span className="home-text45">
                <span>
                  Here are some of the most common questions that we get.
                </span>
                <br></br>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="home-container30">
              <Question
                Answer="ShellHero is a website designed for sysadmins to practice troubleshooting scenarios."
                Question="What is ShellHero?"
              ></Question>
              <Question
                Answer="ShellHero can be used by both individuals looking to improve their troubleshooting skills and companies conducting interviews for sysadmin positions."
                Question="Who can use ShellHero?"
              ></Question>
              <Question
                Answer="ShellHero provides a collection of troubleshooting scenarios for sysadmins to solve. Users can select a scenario, analyze the given information, and try to identify and fix the issue."
                Question="How does ShellHero work?"
              ></Question>
              <Question
                Answer="Yes, ShellHero is a great tool for sysadmins to practice troubleshooting scenarios commonly encountered in job interviews."
                Question="Can I use ShellHero for interview preparation?"
              ></Question>
              <Question
                Answer="Yes, ShellHero offers a free version with limited scenarios. However, there is also a premium subscription available that provides access to a wider range of scenarios and additional features."
                Question="Is ShellHero free to use?"
              ></Question>
            </div>
          </div>
        </div>
      </div>
      <div className="home-footer">
        <footer className="home-footer1">
          <div className="home-container31">
            <span className="home-logo2">SHELLHERO</span>
            <nav className="home-nav1 home-nav1">
              <span className="home-nav12">About</span>
              <span className="home-nav22">Features</span>
              <span className="home-nav32">Pricing</span>
              <span className="home-nav42">Team</span>
              <span className="home-nav52">Blog</span>
            </nav>
          </div>
          <div className="home-separator"></div>
          <div className="home-container32">
            <span className="home-text62">
              © 2023  shellHero, All Rights Reserved.
            </span>
            <div className="home-icon-group1">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon10">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <a
                href="mailto:info@shellhero.io?subject=Contact shellhero"
                className="home-link"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon12">
                  <path d="M928 128h-832c-52.8 0-96 43.2-96 96v640c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-640c0-52.8-43.2-96-96-96zM398.74 550.372l-270.74 210.892v-501.642l270.74 290.75zM176.38 256h671.24l-335.62 252-335.62-252zM409.288 561.698l102.712 110.302 102.71-110.302 210.554 270.302h-626.528l210.552-270.302zM625.26 550.372l270.74-290.75v501.642l-270.74-210.892z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Home
