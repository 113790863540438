import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Routes, Navigate,
} from 'react-router-dom'

import './style.css'
import Register from './views/register'
import Challenges from './views/challenges'
import Login from './views/login'
import Home from './views/home'
import Profile from './views/profile'
import NotFound from './views/not-found'
import ProtectedRoute from "./components/ProtectedRoute";
import {UserAuthContextProvider} from "./context/UserAuthContext";

const App = () => {
  return (
    <Router>
        <UserAuthContextProvider>
            <Routes>
                <Route element={<Home />} exact path="/" />
                <Route element={<Register />} exact path="/register" />
                <Route element={<Login />} exact path="/login" />
                <Route element={<NotFound />} path="/not-found"/>

                <Route element={<ProtectedRoute> <Profile /> </ProtectedRoute>} exact path="/profile" />
                <Route element={<ProtectedRoute> <Challenges /> </ProtectedRoute>} exact path="/challenges" />

                <Route path="*" element={<Navigate to="/not-found"/>} />
            </Routes>
        </UserAuthContextProvider>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
